import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { months } from '../Months';
import { Assumptions } from '../models/Assumptions';
import { ComparisonInput, MaritalStatus } from '../models/ComparisonInput';
import { ComparisonResult } from '../models/ComparisonResult';
import { PersonalData } from '../models/PersonalData';
import { StatementData } from '../models/StatementData';
import { CalculationInput } from './../models/CalculationInput';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService
{
  private calculationInputSubject: BehaviorSubject<CalculationInput> =
    new BehaviorSubject(<CalculationInput>{});
  public calculationInput$: Observable<CalculationInput> =
    this.calculationInputSubject.asObservable();

  private calculationResultSubject: BehaviorSubject<ComparisonResult[]> =
    new BehaviorSubject<ComparisonResult[]>([]);
  public calculationResult$: Observable<ComparisonResult[]> =
    this.calculationResultSubject.asObservable();

  constructor(private http: HttpClient)
  {
  }

  public updateComparisonResult(comparisonResult: ComparisonResult[])
  {
    this.calculationResultSubject.next(comparisonResult);
  }

  public calculate(): Observable<ComparisonResult[]>
  {
    const input = { ...this.calculationInputSubject.value };
    let dOB = new Date();
    dOB.setFullYear(input.personalData.birthYear!);
    dOB.setDate(1);
    dOB.setMonth(this.getMonthNumber(input.personalData.birthMonth!)!);

    let spouseDOB: Date | null = null;

    if (input.personalData.birthYearSpouse !== null)
    {
      spouseDOB = new Date();
      spouseDOB.setFullYear(input.personalData.birthYearSpouse!);
      spouseDOB.setDate(1);

      if (input.personalData.birthMonthSpouse !== null)
      {
        const monthNumber = this.getMonthNumber(
          input.personalData.birthMonthSpouse!
        );
        if (monthNumber !== null)
        {
          spouseDOB.setMonth(monthNumber!);
        }
      }
    }

    let dOH = new Date();
    dOH.setFullYear(input.statementData.joinYear);
    dOH.setDate(1);
    dOH.setMonth(this.getMonthNumber(input.statementData.joinMonth)!);

    const comparisonInput = <ComparisonInput>{
      baseInput: {
        calculationDate: new Date(input.statementData.annualPensionStatementYear, 0),
        retirementAge: input.assumptions.retirementAge || null,
        maritalStatus: input.personalData.maritalStatus || MaritalStatus.Single,
        spouseDob: spouseDOB || null,
        dob: dOB || null,
        doh: dOH || null,
        crdSvc: input.statementData.totalCreditedService || 0,
        pensionableEarnings:
          input.statementData.annualizedPensionableEarnings || 0,
        accruedBenefit: input.statementData.annualDBPensionEarned || 0,
        deferredBenefit: input.statementData.annualDBDeferredPension || 0,
        relationshipBreakdownOffset:
          input.statementData.relationshipBreakdownOffset || 0,
      },
      dbProjAssumptions: {
        salaryIncrease: input.assumptions.salaryGrowth! / 100 || 0,
      },
      dcProjAssumptions: {
        eePerc: input.assumptions.rrspContribution! / 100 || 0,
        riskTolerance: input.assumptions.riskTolerance || null,
      },
    };

    return this.http.post<ComparisonResult[]>(
      `${ environment.apiBaseUrlPrefix }/api/v1/compare`,
      comparisonInput
    );
  }

  public getCalculationInput(): CalculationInput
  {
    return this.calculationInputSubject.value;
  }

  public getCalculationResult(): ComparisonResult[]
  {
    return this.calculationResultSubject.value;
  }

  public setAssumptions(assumptions: Assumptions)
  {
    this.calculationInputSubject.value.assumptions = assumptions;
    this.calculationInputSubject.next(this.calculationInputSubject.value);
  }

  public setPersonalData(personalData: PersonalData)
  {
    this.calculationInputSubject.value.personalData = personalData;
    this.calculationInputSubject.next(this.calculationInputSubject.value);
  }

  public setStatementData(statementData: StatementData)
  {
    this.calculationInputSubject.value.statementData = statementData;
    this.calculationInputSubject.next(this.calculationInputSubject.value);
  }

  private getMonthNumber(monthName: string): number | undefined
  {
    const normalizedMonthName = monthName.toLowerCase();
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === normalizedMonthName
    );

    if (monthIndex !== -1)
    {
      return monthIndex;
    } else
    {
      return undefined;
    }
  }
}
