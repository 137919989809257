import { Component } from '@angular/core';

@Component({
  selector: 'app-salary-growth-info',
  templateUrl: './salary-growth-info.component.html',
  styleUrls: ['./salary-growth-info.component.scss']
})
export class SalaryGrowthInfoComponent {

}
