
export interface ComparisonInput{
    baseInput: {
        calculationDate: Date;
        description:string;
        retirementAge:number;
        maritalStatus: MaritalStatus;
        spouseDob?: Date;
        dob: Date;
        doh: Date;
        crdSvc: number;
        pensionableEarnings: number;
        accruedBenefit: number;
        deferredBenefit?: number;
        relationshipBreakdownOffset?: number;
    };
    dbProjAssumptions: {
        salaryIncrease: number;
    };
    dcProjAssumptions: {
        eePerc: number;
        riskTolerance: RiskTolerance;
    };
}

export enum MaritalStatus {
    Single = 1,
    Married = 2,
    Other = 3,
}

export enum RiskTolerance {
    VeryLow = 1,
    Low = 2,
    Moderate = 3,
    High = 4,
    VeryHigh = 5,
}