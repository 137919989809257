import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { ComparisonResult } from 'src/app/models/ComparisonResult';
import { AppService } from 'src/app/services/app.service';
import { DisclaimerComponent } from '../dialogs/assumptions/disclaimer/disclaimer.component';
import { CurrentPensionPlanInfoComponent } from '../dialogs/comparison/current-pension-plan-info/current-pension-plan-info.component';
import { NewGroupRrspInfoComponent } from '../dialogs/comparison/new-group-rrsp-info/new-group-rrsp-info.component';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { LearnMoreInfoComponent } from '../dialogs/learn-more-info/learn-more-info.component';
import { ResultsDialogComponent } from '../results-dialog/results-dialog.component';
import { StackedBarChartComponent } from '../stacked-bar-chart/stacked-bar-chart.component';
import { RiskTolerance } from './../../models/ComparisonInput';
import { CalculatorService } from './../../services/calculator.service';
@Component({
  selector: 'app-comparison-results',
  templateUrl: './comparison-results.component.html',
  styleUrls: ['./comparison-results.component.scss'],
})
export class ComparisonResultsComponent implements OnInit, OnDestroy {
  @Input() isPrint: boolean = false;
  @ViewChild(StackedBarChartComponent)
  chartComponent!: StackedBarChartComponent;
  public calculationInput: CalculationInput | undefined = undefined;
  public comparisonResults: ComparisonResult[] | undefined = undefined;
  public retireAgeFirstMonth!: ComparisonResult;
  public displayToggle: string = 'chart';
  public currentYear: Number = new Date().getFullYear();
  public isFormattingPrint: Boolean = false;

  private RiskTolerance = RiskTolerance;
  private calcInput$!: Subscription;
  private calcResult$!: Subscription;

  constructor(
    public appService: AppService,
    public calculatorService: CalculatorService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.calcInput$ = this.calculatorService.calculationInput$.subscribe(
      (result: CalculationInput) => {
        if (result) this.calculationInput = result;
      }
    );

    this.calcResult$ = this.calculatorService.calculationResult$.subscribe(
      (result: ComparisonResult[]) => {
        if (result.length > 1 && this.calculationInput) {
          this.comparisonResults = result;

          this.retireAgeFirstMonth = this.comparisonResults.find(
            (result: ComparisonResult) =>
              result.riskTolerance ===
              this.RiskTolerance[
                this.calculationInput!.assumptions.riskTolerance!
              ]
                .toLocaleLowerCase()
                .replace('very', 'very ') &&
              result.fractionalAge &&
              result.fractionalAge ===
              Math.trunc(this.calculationInput?.assumptions.retirementAge!)
          )!;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.calcInput$) this.calcInput$.unsubscribe();
    if (this.calcResult$) this.calcResult$.unsubscribe();
  }

  public openCurrentPensionPlanDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'DB Pension Plan',
        dialogContent: CurrentPensionPlanInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public print(): void {
    this.isFormattingPrint = true;

    this.appService.nextStepsEnabled = true;
    this.appService.nextStepsExpanded = true;

    this.displayToggle = 'chart';
    setTimeout(() => {
      const chartContainer = this.chartComponent.getChartContainer();
      chartContainer.style.margin = '0';
      chartContainer.style.width = '40vw';
      this.appService.isPrint = true;
      setTimeout(() => {
        window.print();
        chartContainer.style.margin = 'auto';
        chartContainer.style.width = 'auto';
        this.isFormattingPrint = false;
      }, 1000);
    }, 1000);
  }

  public openNewGroupRRSPDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'New Group RRSP',
        dialogContent: NewGroupRrspInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public openLearnMoreDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Learn more about your DB commuted value',
        dialogContent: LearnMoreInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public openDisclaimerDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Disclaimer',
        dialogContent: DisclaimerComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public openResultsDialog(): void {
    const dialogRef = this.dialog.open(ResultsDialogComponent, {
      data: {
        isChangeAssumptions: true,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'edit') {
        this.appService.personalDataExpanded = true;
        this.appService.comparisonExpanded = false;
        if (this.appService.nextStepsEnabled) this.appService.nextStepsExpanded = false;
      }
      if (result === 'compare') {
        this.appService.assumptionsExpanded = false;
        this.appService.comparisonEnabled = true;
        this.appService.comparisonExpanded = true;
        if (this.appService.nextStepsEnabled) this.appService.nextStepsExpanded = false;
      }
    });
  }

  public next(): void {
    this.appService.comparisonExpanded = false;
    this.appService.nextStepsEnabled = true;
    this.appService.nextStepsExpanded = true;
  }
}
