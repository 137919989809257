<p>
  In the new Group RRSP, you will have an opportunity to choose how all
  contributions are invested. While this gives you greater control, it also
  raises the question: how do I make the right investment decisions? There are
  several factors to consider – your comfort with risk, how long you’ll be
  investing your savings, and how much you’re hoping to save. Answers to these
  questions will be unique to you, so what’s right for you may not be right for
  your spouse, your colleague, or your friend.
</p>
<p>
  Generally speaking, investments associated with higher returns are also
  associated with higher risk. However, the risk may be more acceptable if your
  goal is longer-term because you have more time to recover from any financial
  losses. If you are investing for retirement and it is 15, 20 or 25+ years
  away, you have lots of time to weather the financial markets and benefit from
  any long-term upward trends. If you only have a few years to invest, then you may be more comfortable playing it safe
  and not subjecting your savings to a possible market
  decline.
</p>
<p>
  The importance of time horizon in investment decisions is highlighted by
  looking at historical returns. Over the past 50 years, when the length of
  investment was 12 months, the return on global equities (a higher risk
  investment) ranged from -39% to +71%. However, when the length of investment
  was 20 years, global equities returned between +4% and +17% annualized.
  Looking at Canadian bonds (a lower risk investment), 12-month returns varied
  between -12% and +22%, whereas 20-year investments resulted in annualized
  returns between +4% and +8%. Note both the reduction in variability for the
  longer-term investments, as well as the higher historical return from
  long-term investment in equities.
  <i>(Source: eVestment, rolling 12-month MSCI World ($CAD) & FTSE Canada
    Universe returns, January 1970 to December 2022. Future returns may
    different significantly from historical returns.)</i>
</p>
<p>
  In general, the longer your investing time frame, the more risk you can afford
  to take. When investing for a goal that is many years into the future, you can
  accept more risk because you won’t need to access the money for a long time
  and the riskier strategy is expected to provide greater investment growth over
  time.
</p>
<p>
  Your comfort and tolerance for a certain level of risk will also depend on
  your overall ability and willingness to accept risk. Ask yourself, how would a
  decline in my Group RRSP balance impact my retirement goals? It’s a good gut
  check to make sure you are invested with your goals and comfortable with the
  potential outcomes
</p>
<p>
  This tool allows you to forecast your pension income based on various
  investment risk/return scenarios and underlying assumptions that are
  reasonable based on historical returns. While selecting a higher risk/return
  assumption will produce higher estimates of future income, note that these
  outcomes are not promised. You may wish to discuss your retirement goals and
  risk tolerance with a professional financial advisor before making a decision.
</p>