<div class="dialog">
    <div class="content" mat-dialog-content>
        <p [innerHTML]="sanitizedContent"></p>
    </div>
    <div class="buttonArea" mat-dialog-actions>
        <img class="topImg" src="{{ imagePath }}" alt="Image2" />
    </div>
    <div class="buttonArea" mat-dialog-actions>
        <button class="closeButton" mat-button (click)="onNoClick()">Close</button>
    </div>
</div>
