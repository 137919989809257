import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  public canActivate(accessKey: string): boolean {

    if(environment.production){
      if(environment.accessKey === accessKey)return true;
      else return false;
    }
    else if(!environment.production)return true;

    return false
  }
}
