<p>
  This information will be used to estimate your continuous service with AMA –
  that is, your most recent uninterrupted period of AMA employment. The tool
  uses continuous service to estimate your membership date for the current
  pension plan.
</p>
<p>
  You can find the <b>Date you joined the AMA family</b> in your Annual Pension Statement.
</p>
<div class="dialog-image-wrapper">
  <img src="../../../images/month-year-diolog.png" alt="" />
</div>
<p>
  To limit the amount of personal data you need to enter - and because the tool's results are
  rough estimates - we require only your month and year of joining AMA.
</p>