<div class="dialog">
  <h2 *ngIf="!data.isChangeAssumptions">A quick double-check!</h2>
  <h2 *ngIf="data.isChangeAssumptions">Your responses</h2>
  <mat-dialog-content>
    <p>
      Here is a summary of your inputs - please confirm that they are accurate:
    </p>
    <div>
      <h3>Your personal data</h3>
      <p>
        Your month & year of birth:
        <strong>
          {{
          calculationInput.personalData.birthMonth != null
          ? calculationInput.personalData.birthMonth
          : ""
          }}
          {{
          calculationInput.personalData.birthYear != null
          ? calculationInput.personalData.birthYear
          : ""
          }}
        </strong>
      </p>
      <p *ngIf="isOlderThan55()">
        Do you currently have a spouse:
        <strong>
          {{ calculationInput.personalData.maritalStatus === 2 ? "Yes" : "No" }}
        </strong>
      </p>
      <p *ngIf="calculationInput.personalData.maritalStatus === 2">
        Your spouse’s month & year of birth:
        <strong>
          {{
          calculationInput.personalData.birthMonthSpouse != null
          ? calculationInput.personalData.birthMonthSpouse
          : ""
          }}
          {{
          calculationInput.personalData.birthYearSpouse != null
          ? calculationInput.personalData.birthYearSpouse
          : ""
          }}
        </strong>
      </p>
    </div>
    <div>
      <h3>Your statement data</h3>
      <p>
        Month & year of joining the AMA family:
        <strong>
          {{
          calculationInput.statementData.joinMonth != null
          ? calculationInput.statementData.joinMonth
          : ""
          }}
          {{
          calculationInput.statementData.joinYear != null
          ? calculationInput.statementData.joinYear
          : ""
          }}
        </strong>
      </p>
      <p>
        Annual Pension Statement year:
        <strong>
          {{
          calculationInput.statementData.annualPensionStatementYear
          }}
        </strong>
      </p>
      <p *ngIf="
          calculationInput.statementData.totalCreditedService !== undefined &&
          calculationInput.statementData.totalCreditedService !== null
        ">
        Total credited service at end of {{calculationInput.statementData.annualPensionStatementYear != null ?
        calculationInput.statementData.annualPensionStatementYear : "statement year"}}:
        <strong>
          {{
          calculationInput.statementData.totalCreditedService != null
          ? calculationInput.statementData.totalCreditedService + " years"
          : ""
          }}
        </strong>
      </p>
      <p>
        {{
        calculationInput.statementData.annualPensionStatementYear != null
        ? calculationInput.statementData.annualPensionStatementYear
        : ""
        }} pensionable earnings:<strong>
          {{
          calculationInput.statementData.annualizedPensionableEarnings
          | currency
          }}
        </strong>
      </p>
      <p *ngIf="
          calculationInput.statementData.annualDBPensionEarned !== undefined &&
          calculationInput.statementData.annualDBPensionEarned !== null
        ">
        DB pension earned to end of {{calculationInput.statementData.annualPensionStatementYear != null ?
        calculationInput.statementData.annualPensionStatementYear : "statement year"}}:<strong>
          {{ calculationInput.statementData.annualDBPensionEarned | currency }}
        </strong>
      </p>
      <p *ngIf="
          calculationInput.statementData.annualDBDeferredPension !==
            undefined &&
          calculationInput.statementData.annualDBDeferredPension !== null
        ">
        Annual DB deferred pension:<strong>
          {{
          calculationInput.statementData.annualDBDeferredPension | currency
          }}
        </strong>
      </p>
      <p *ngIf="
          calculationInput.statementData.relationshipBreakdownOffset !==
            undefined &&
          calculationInput.statementData.relationshipBreakdownOffset !== null
        ">
        Relationship breakdown offset:
        <strong>{{
          calculationInput.statementData.relationshipBreakdownOffset
          | currency
          }}
        </strong>
      </p>
    </div>
    <div>
      <h3>Your assumptions</h3>
      <p>
        Your expected salary growth:
        <strong>
          {{
          calculationInput.assumptions.salaryGrowth != null
          ? calculationInput.assumptions.salaryGrowth + "%"
          : ""
          }}
        </strong>
      </p>
      <p *ngIf="calculationInput.assumptions.retirementAge">
        Your planned retirement age:
        <strong>
          {{
          calculationInput.assumptions.retirementAge != null
          ? calculationInput.assumptions.retirementAge
          : ""
          }} years old
        </strong>
      </p>
      <!-- <p>
        Your expected Group RRSP contribution:
        <strong>
          {{
            calculationInput.assumptions.rrspContribution != null
              ? calculationInput.assumptions.rrspContribution + "%"
              : ""
          }}
        </strong>
      </p>
      <p>
        Your expected investment risk profile:
        <strong>
          {{
            calculationInput.assumptions.riskTolerance != null
              ? RiskTolerance[calculationInput.assumptions.riskTolerance].replace('Very', 'Very ')
              : ""
          }}
        </strong>
      </p> -->
    </div>
    <div class="error-wrapper" *ngIf="isErrorInCalc">
      <label>There was an error while calculating. Please review input fields and retry.</label>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button mat-flat-button color="secondary" (click)="editPersonalData()" [disabled]="isCalculating">
      Make corrections
    </button>
    <button mat-button mat-flat-button color="primary" (click)="viewResults()" [disabled]="isCalculating">
      View your results!
    </button>
    <mat-progress-bar mode="indeterminate" *ngIf="isCalculating"></mat-progress-bar>
    <br />
  </mat-dialog-actions>
</div>