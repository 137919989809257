<app-expansion-panel [(panelOpenState)]="appService.statementDataExpanded" [enabled]="appService.statementDataEnabled">
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_statementdata.svg" />
    <h1>Your statement data</h1>
  </div>
  <p class="noprint">*All fields are required</p>
  <form [formGroup]="statementDataFG" #form="ngForm" (ngSubmit)="next()">

    <!--  -->
    <label class="info">Month & year you joined the AMA family:<img src="../images/icon.svg"
        (click)="openJoinDateInfoDialog()" /></label>
    <mat-form-field>
      <mat-label>Month of joining</mat-label>
      <mat-select matNativeControl required formControlName="joinMonth">
        <mat-option *ngFor="let month of months" [value]="month">{{
          month
          }}</mat-option>
      </mat-select>
      <mat-error *ngIf="statementDataFG.controls.joinMonth.hasError('required')">*Required</mat-error>
    </mat-form-field>
    <!--  -->

    <mat-form-field>
      <mat-label>Year of joining</mat-label>
      <input matInput type="number" formControlName="joinYear" />

      <mat-error *ngIf="
        statementDataFG.controls.joinYear.hasError('min') ||
        statementDataFG.controls.joinYear.hasError('max')
        ">
        Your year of joining AMA must be between {{ minJoinYear }} &
        {{ maxJoinYear }}
      </mat-error>
      <mat-error *ngIf="statementDataFG.controls.joinYear.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <label *ngIf="showEarnings" class="info">Your Annual Pension Statement year:<img
        (click)="openAnnualPensionStatementYearInfoDialog()" src="../images/icon.svg" />
    </label>

    <mat-form-field *ngIf="showEarnings">
      <mat-label>Annual Pension Statement year</mat-label>

      <!-- <input matInput formControlName="annualPensionStatementYear" /> -->
      <mat-select matNativeControl required formControlName="annualPensionStatementYear">
        <mat-option *ngFor="let yr of joinYears" [value]="yr">{{
          yr
          }}</mat-option>
      </mat-select>
      <mat-error *ngIf="statementDataFG.controls.annualPensionStatementYear.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>

    <!--  -->

    <label *ngIf="showEarnings" class="info">Total credited service as of statement year:<img
        (click)="openCreditedServiceInfoDialog()" src="../images/icon.svg" />
    </label>

    <mat-form-field *ngIf="showEarnings">
      <mat-label>Credited service at statement year</mat-label>

      <input matInput formControlName="totalCreditedService" currencyMask
        [options]="{ precision: 2, prefix: '', decimal: '.' }" />
      <span matSuffix>year(s)</span>
      <mat-error *ngIf="
        statementDataFG.controls.totalCreditedService.hasError('min') ||
        statementDataFG.controls.totalCreditedService.hasError('max')
        ">
        Your year of joining AMA must be between 0 & 55
      </mat-error>
      <mat-error *ngIf="statementDataFG.controls.totalCreditedService.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <!-- <label class="info" *ngIf="pensionableEarningsDate === 2023">Annualized pensionable earnings for the most recent
      year:<img src="../images/icon.svg" (click)="openPensionableEarningsInfoDialog()" /></label> -->
    <label class="info" *ngIf="showEarnings">Annualized pensionable earnings for the most recent statement
      year:<img src="../images/icon.svg" (click)="openPensionableEarningsInfoDialog()" /></label>

    <mat-form-field *ngIf="showEarnings">
      <mat-label>Annualized pensionable earnings</mat-label>
      <input currencyMask matInput formControlName="annualizedPensionableEarnings" />
      <mat-error *ngIf="
        statementDataFG.controls.annualizedPensionableEarnings.hasError(
            'min'
          ) ||
          statementDataFG.controls.annualizedPensionableEarnings.hasError('max')
        ">
        Your pensionable earnings must be between $0 & $999,999
      </mat-error>
      <mat-error *ngIf="statementDataFG.controls.totalCreditedService.hasError('required')">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <label class="info" *ngIf="showEarnings">Annual DB pension earned as of statement year:<img
        (click)="openDBPensionInfoDialog()" src="../images/icon.svg" />
    </label>
    <mat-form-field *ngIf="showEarnings">
      <mat-label>DB pension earned at statement year</mat-label>
      <input currencyMask matInput formControlName="annualDBPensionEarned" />
      <mat-error *ngIf="
        statementDataFG.controls.annualDBPensionEarned.hasError('min') ||
        statementDataFG.controls.annualDBPensionEarned.hasError('max')
        ">
        Your annual DB pension must be between $0 & $999,999
      </mat-error>
      <mat-error *ngIf="
        statementDataFG.controls.annualDBPensionEarned.hasError('required')
        ">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <label class="info">Do you have an annual DB deferred pension?<img (click)="openDeferredPensionInfoDialog()"
        src="../images/icon.svg" />
    </label>
    <mat-radio-group formControlName="hasDeferredPension">
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
    <!--  -->

    <label class="info" *ngIf="statementDataFG.controls['hasDeferredPension'].value!">Annual DB deferred pension:<img
        src="../images/icon.svg" (click)="openDeferredPensionAmountInfoDialog()" />
    </label>
    <mat-form-field *ngIf="statementDataFG.controls['hasDeferredPension'].value!">
      <mat-label>Deferred pension</mat-label>
      <input currencyMask matInput formControlName="annualDBDeferredPension" />
      <mat-error *ngIf="
        statementDataFG.controls.annualDBDeferredPension.hasError('min') ||
        statementDataFG.controls.annualDBDeferredPension.hasError('max')
        ">
        Your annual deferred pension must be between $0 & $999,999
      </mat-error>
      <mat-error *ngIf="
        statementDataFG.controls.annualDBDeferredPension.hasError('required')
        ">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <label class="info">Do you have a relationship breakdown offset?<img
        (click)="openRelationshipOffsetFlagInfoDialog()" src="../images/icon.svg" />
    </label>
    <mat-radio-group formControlName="hasRelationshipBreakdownOffset">
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
    <!--  -->

    <label class="info" *ngIf="statementDataFG.controls['hasRelationshipBreakdownOffset'].value!">Relationship breakdown
      offset:<img src="../images/icon.svg" (click)="openRelationshipOffsetAmountInfoDialog()" />
    </label>
    <mat-form-field *ngIf="statementDataFG.controls['hasRelationshipBreakdownOffset'].value!">
      <mat-label>Relationship breakdown offset</mat-label>
      <input currencyMask matInput formControlName="relationshipBreakdownOffset" />
      <mat-error *ngIf="
      statementDataFG.controls.relationshipBreakdownOffset.hasError('min') ||
        statementDataFG.controls.relationshipBreakdownOffset.hasError('max')
      ">
        Your annual deferred pension must be between $0 & $ {{ statementDataFG.controls['annualDBPensionEarned'].value!
        | currency }}
      </mat-error>
      <mat-error *ngIf="
        statementDataFG.controls.relationshipBreakdownOffset.hasError('required')
      ">
        *Required
      </mat-error>
    </mat-form-field>
    <!--  -->

    <div class="break"></div>
    <button mat-flat-button color="primary" [disabled]="!statementDataFG.valid">
      {{ !form.submitted ? 'Save & Continue' : 'Update & Continue' }}
    </button>
  </form>
</app-expansion-panel>