import
{
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { months } from 'src/app/Months';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { StatementData } from 'src/app/models/StatementData';
import { AppService } from 'src/app/services/app.service';
import { CalculatorService } from 'src/app/services/calculator.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { AnnualPensionStatementYearInfoComponent } from '../dialogs/statement-data/annual-pension-statement-year-info/annual-pension-statement-year-info.component';
import { CreditedServiceInfoComponent } from '../dialogs/statement-data/credited-service-info/credited-service-info.component';
import { DbPensionInfoComponent } from '../dialogs/statement-data/db-pension-info/db-pension-info.component';
import { DeferredPensionAmountInfoComponent } from '../dialogs/statement-data/deferred-pension-amount-info/deferred-pension-amount-info.component';
import { DeferredPensionInfoComponent } from '../dialogs/statement-data/deferred-pension-info/deferred-pension-info.component';
import { JoinDateInfoComponent } from '../dialogs/statement-data/join-date-info/join-date-info.component';
import { PensionableEarningsInfoComponent } from '../dialogs/statement-data/pensionable-earnings-info/pensionable-earnings-info.component';
import { RelationshipOffsetAmountInfoComponent } from '../dialogs/statement-data/relationship-offset-amount-info/relationship-offset-amount-info.component';
import { RelationshipOffsetFlagInfoComponent } from '../dialogs/statement-data/relationship-offset-flag-info/relationship-offset-flag-info.component';

@Component({
  selector: 'app-statement-data',
  templateUrl: './statement-data.component.html',
  styleUrls: ['./statement-data.component.scss'],
})
export class StatementDataComponent implements OnInit, OnDestroy
{
  @Input() isEnabled: boolean = false;
  public months = months;
  public joinYears: number[] = [];
  public formattedTotalCreditedService: string = '';
  public minJoinYear!: number;
  public maxJoinYear!: number;
  public showEarnings!: boolean;
  public pensionableEarningsDate!: number;
  public statementDataFG = new FormGroup({
    joinMonth: new FormControl<string | null>(null, Validators.required),
    annualPensionStatementYear: new FormControl<number | null>(null),
    joinYear: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(2023),
    ]),
    totalCreditedService: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(55),
    ]),
    annualizedPensionableEarnings: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(999999),
    ]),
    annualDBPensionEarned: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(999999),
    ]),
    hasDeferredPension: new FormControl<boolean | null>(
      null,
      Validators.required
    ),
    annualDBDeferredPension: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(999999),
    ]),
    hasRelationshipBreakdownOffset: new FormControl<boolean | null>(
      null,
      Validators.required
    ),
    relationshipBreakdownOffset: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
    ]),
  });

  private calcInput$!: Subscription;
  private annualDBPension$!: Subscription;
  private joinYear$!: Subscription;
  private joinYearMonth$!: Subscription;
  private hasDeferredPension$!: Subscription;
  private hasRelationshipBreakdownOffset$!: Subscription;

  constructor(
    public appService: AppService,
    public calculatorService: CalculatorService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void
  {
    this.subscribeToYearMonthChange();

    this.pushYearsBasedOnDate();


    this.calcInput$ = this.calculatorService.calculationInput$.subscribe(
      (calcInput: CalculationInput) =>
      {
        if (calcInput.personalData)
        {
          this.minJoinYear = calcInput.personalData.birthYear! + 15;
          this.maxJoinYear = calcInput.personalData.birthYear! + 70;

          this.statementDataFG.controls.joinYear.setValidators([
            Validators.required,
            Validators.min(this.minJoinYear),
            Validators.max(this.maxJoinYear),
          ]);


        }
      }
    );

    this.annualDBPension$ =
      this.statementDataFG.controls.annualDBPensionEarned.valueChanges.subscribe(
        (annualDBPension: number | null) =>
        {
          if (this.annualDBPension$)
          {
            this.statementDataFG.controls.relationshipBreakdownOffset.setValidators(
              [
                Validators.required,
                Validators.min(0),
                Validators.max(annualDBPension!),
              ]
            );

          }
        }
      );

    this.joinYear$ =
      this.statementDataFG.controls.joinYear.valueChanges.subscribe(
        (joinYear: number | null) =>
        {
          const calcInput = this.calculatorService.getCalculationInput();
          if (calcInput.personalData)
          {
            this.minJoinYear = calcInput.personalData.birthYear! + 15;
            this.maxJoinYear = new Date().getFullYear() - 1;

            this.statementDataFG.controls.joinYear.setValidators([
              Validators.required,
              Validators.min(this.minJoinYear),
              Validators.max(this.maxJoinYear),
            ]);

          }

          if (joinYear == undefined || joinYear == null || joinYear < 1000 || joinYear > 9999)
          {
            return;
          }

          this.configurePensionInputAccessibility(joinYear!, 1);

        }
      );

    this.hasDeferredPension$ =
      this.statementDataFG.controls.hasDeferredPension.valueChanges.subscribe(
        (hasDeferredPension: boolean | null) =>
        {
          if (hasDeferredPension === true)
            this.statementDataFG.controls.annualDBDeferredPension.enable();
          else
          {
            this.statementDataFG.controls.annualDBDeferredPension.setValue(
              null
            );
            this.statementDataFG.controls.annualDBDeferredPension.markAsUntouched();
            this.statementDataFG.controls.annualDBDeferredPension.disable();
          }
        }
      );

    this.hasRelationshipBreakdownOffset$ =
      this.statementDataFG.controls.hasRelationshipBreakdownOffset.valueChanges.subscribe(
        (hasRelationshipBreakdownOffset: boolean | null) =>
        {
          if (hasRelationshipBreakdownOffset === true)
            this.statementDataFG.controls.relationshipBreakdownOffset.enable();
          else
          {
            this.statementDataFG.controls.relationshipBreakdownOffset.enable();
            this.statementDataFG.controls.relationshipBreakdownOffset.setValue(
              null
            );
            this.statementDataFG.controls.relationshipBreakdownOffset.markAsUntouched();
            this.statementDataFG.controls.relationshipBreakdownOffset.disable();
          }
        }
      );
  }

  private pushYearsBasedOnDate(): void
  {
    const currentDate = new Date();  // Captures today's date
    const currentYear = currentDate.getFullYear();  // Extracts the year part of the date

    if (currentDate < new Date(currentYear, 5, 1))
    {
      // Current date is on or before May 30
      this.joinYears.push(currentYear - 2, currentYear - 3, currentYear - 4);
    } else
    {
      // Current date is on or after June 1
      this.joinYears.push(currentYear - 1, currentYear - 2, currentYear - 3);
    }
  }

  private subscribeToYearMonthChange()
  {
    this.joinYearMonth$ = merge(this.statementDataFG.controls.joinYear.valueChanges.pipe(
      filter(year => typeof year === 'number' && year !== null && year >= 1000 && year <= 9999)
    ),
      this.statementDataFG.controls.joinMonth.valueChanges).subscribe(
        (mergedYearMonth) =>
        {
          this.OnMonthOrYearofJoiningChanged(this.statementDataFG.controls.joinYear.value, this.statementDataFG.controls.joinMonth.value);
        }
      );
  }

  OnMonthOrYearofJoiningChanged(year: number | null, month: string | null): void
  {
    if (year == undefined || year == null || year < 1000 || year > 9999 || month == undefined || month == null)
    {
      return;
    }

    //this.configurePensionInputAccessibility(year, 1);

  }

  configurePensionInputAccessibility(joinYear: number, month: number): void
  {
    if (joinYear && joinYear > 1900)
    {
      this.statementDataFG.controls.annualizedPensionableEarnings.setValue(
        null
      );
      this.statementDataFG.controls.totalCreditedService.setValue(null);
      this.statementDataFG.controls.annualDBPensionEarned.setValue(null);
      this.statementDataFG.controls.annualizedPensionableEarnings.markAsUntouched();
      this.statementDataFG.controls.totalCreditedService.markAsUntouched();
      this.statementDataFG.controls.annualDBPensionEarned.markAsUntouched();

      this.showEarnings = true;
      // if (joinYear > 2022)
      // {
      //   this.pensionableEarningsDate = 2023;
      //   this.showEarnings = false;
      //   this.statementDataFG.controls.annualizedPensionableEarnings.enable();
      //   this.statementDataFG.controls.totalCreditedService.disable();
      //   this.statementDataFG.controls.annualDBPensionEarned.disable();
      // } else
      {
        this.pensionableEarningsDate = 2022;
        this.statementDataFG.controls.annualizedPensionableEarnings.enable();
        this.statementDataFG.controls.totalCreditedService.enable();
        this.statementDataFG.controls.annualDBPensionEarned.enable();
      }
    } else
    {
      this.statementDataFG.controls.annualizedPensionableEarnings.disable();
      this.statementDataFG.controls.totalCreditedService.disable();
      this.statementDataFG.controls.annualDBPensionEarned.disable();
      this.showEarnings = false;
    }
  }

  public next(): void
  {
    this.calculatorService.setStatementData(
      <StatementData>this.statementDataFG.getRawValue()
    );
    this.appService.statementDataExpanded = false;
    this.appService.assumptionsEnabled = true;
    this.appService.assumptionsExpanded = true;
  }

  public openJoinDateInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Month & year you joined the AMA family',
        dialogContent: JoinDateInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openAnnualPensionStatementYearInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Your Annual Pension Statement year',
        dialogContent: AnnualPensionStatementYearInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openCreditedServiceInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Total credited service as of statement year',
        dialogContent: CreditedServiceInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openPensionableEarningsInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Annualized pensionable earnings for the most recent statement year',
        dialogContent: PensionableEarningsInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openDBPensionInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Annual DB pension earned as of most recent statement year',
        dialogContent: DbPensionInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openDeferredPensionInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Do you have an annual DB deferred pension?',
        dialogContent: DeferredPensionInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openDeferredPensionAmountInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Annual DB deferred pension',
        dialogContent: DeferredPensionAmountInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openRelationshipOffsetFlagInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Do you have a relationship breakdown offset?',
        dialogContent: RelationshipOffsetFlagInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openRelationshipOffsetAmountInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Relationship breakdown offset',
        dialogContent: RelationshipOffsetAmountInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  ngOnDestroy(): void
  {
    if (this.calcInput$) this.calcInput$.unsubscribe();
    if (this.annualDBPension$) this.annualDBPension$.unsubscribe();
    if (this.joinYear$) this.joinYear$.unsubscribe();
    if (this.joinYearMonth$) this.joinYearMonth$.unsubscribe();
    if (this.hasDeferredPension$) this.hasDeferredPension$.unsubscribe();
    if (this.hasRelationshipBreakdownOffset$)
      this.hasRelationshipBreakdownOffset$.unsubscribe();
  }
}
