<p>
  This information will be used when estimating an annual pension income from
  your forecasted Group RRSP balance at retirement, accounting for potential survivor
  benefits that may be paid to your spouse. Note that, for the purposes of this tool,
  a spouse is defined as a person who you are married to and living with, or – if
  you are not married – a person who has been living with you in a conjugal relationship
  for at least 3 years (less than 3 years if you share custody of a child with them).
</p>
<p>
  To limit the amount of personal data you need to enter – and because the tool’s results
  are rough estimates – we require only your spouse’s month and year of birth.
</p>