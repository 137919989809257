<div class="dialog">
  <h2>{{ data.title }}</h2>
  <mat-dialog-content>
    <ng-container [ngComponentOutlet]="data.dialogContent"> </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">
      Close
    </button>
  </mat-dialog-actions>
</div>
