import { Component } from '@angular/core';

@Component({
  selector: 'app-pensionable-earnings-info',
  templateUrl: './pensionable-earnings-info.component.html',
  styleUrls: ['./pensionable-earnings-info.component.scss']
})
export class PensionableEarningsInfoComponent {

}
