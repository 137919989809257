import { Component } from '@angular/core';

@Component({
  selector: 'app-annual-pension-statement-year-info',
  templateUrl: './annual-pension-statement-year-info.component.html',
  styleUrls: ['./annual-pension-statement-year-info.component.scss']
})
export class AnnualPensionStatementYearInfoComponent {

}
