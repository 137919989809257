import { Component } from '@angular/core';

@Component({
  selector: 'app-learn-more-info',
  templateUrl: './learn-more-info.component.html',
  styleUrls: ['./learn-more-info.component.scss']
})
export class LearnMoreInfoComponent {

}
