import
{
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { months } from 'src/app/Months';
import { Assumptions } from 'src/app/models/Assumptions';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { RiskTolerance } from 'src/app/models/ComparisonInput';
import { AppService } from 'src/app/services/app.service';
import { CalculatorService } from 'src/app/services/calculator.service';
import { PlannedRetirementInfoComponent } from '../dialogs/assumptions/planned-retirement-info/planned-retirement-info.component';
import { SalaryGrowthInfoComponent } from '../dialogs/assumptions/salary-growth-info/salary-growth-info.component';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { ResultsDialogComponent } from '../results-dialog/results-dialog.component';
import { DisclaimerComponent } from './../dialogs/assumptions/disclaimer/disclaimer.component';
import { InvestmentRiskInfoComponent } from './../dialogs/assumptions/investment-risk-info/investment-risk-info.component';
import { RrspContributionInfoComponent } from './../dialogs/assumptions/rrsp-contribution-info/rrsp-contribution-info.component';
@Component({
  selector: 'app-assumptions',
  templateUrl: './assumptions.component.html',
  styleUrls: ['./assumptions.component.scss'],
})
export class AssumptionsComponent implements OnInit
{
  @Input() isEnabled: boolean = false;
  public RiskTolerance = RiskTolerance;
  public calculationInput!: CalculationInput;
  private calcInput$!: Subscription;
  public months = months;
  public minRetireYear!: number;
  public assumptionsFG = new FormGroup({
    salaryGrowth: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(4),
    ]),
    // rrspContribution: new FormControl<number | null>(null, [
    //   Validators.required,
    //   Validators.min(0),
    //   Validators.max(18),
    // ]),
    riskTolerance: new FormControl<RiskTolerance | null>(
      3
    ),
    retirementAge: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(71),
    ]),
  });

  constructor(
    public appService: AppService,
    public calculatorService: CalculatorService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void
  {
    this.calculationInput = this.calculatorService.getCalculationInput();
    this.calcInput$ = this.calculatorService.calculationInput$.subscribe(
      (calcInput: CalculationInput) =>
      {
        if (calcInput.personalData)
        {
          const currentYear = new Date();
          this.minRetireYear =
            currentYear.getFullYear() - calcInput.personalData.birthYear! + 1;
          if (this.minRetireYear < 55)
          {
            this.minRetireYear = 55;
          }
          this.assumptionsFG.controls.retirementAge.setValidators(null);
          this.assumptionsFG.controls.retirementAge.setValidators([
            Validators.required,
            Validators.min(this.minRetireYear),
            Validators.max(71),
          ]);
          this.assumptionsFG.controls.retirementAge.updateValueAndValidity();
        }
      }
    );
  }

  public openResultsDialog(): void
  {
    this.calculatorService.setAssumptions(
      <Assumptions>this.assumptionsFG.getRawValue()
    );

    const dialogRef = this.dialog.open(ResultsDialogComponent, {
      data: {
        isChangeAssumptions: false,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (result === 'edit')
      {
        this.appService.assumptionsExpanded = false;
        this.appService.comparisonExpanded = false;
        this.appService.personalDataExpanded = true;
      }
      if (result === 'compare')
      {
        this.appService.assumptionsExpanded = false;
        this.appService.comparisonEnabled = true;
        this.appService.comparisonExpanded = true;
      }
    });
  }

  public openSalaryGrowthInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'How much do you expect your base salary to grow each year?',
        dialogContent: SalaryGrowthInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openPlannedRetirementAgeDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'What is your planned retirement age?',
        dialogContent: PlannedRetirementInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openRRSPContributionInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'If you join the New Group RRSP…',
        dialogContent: RrspContributionInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openInvestmentRiskInfoDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'What level of investment risk will you likely take on?',
        dialogContent: InvestmentRiskInfoComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public openDisclaimerDialog(): void
  {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Disclaimer',
        dialogContent: DisclaimerComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (!result) return;
    });
  }

  public isAbove55(): boolean
  {
    if (
      this.calculationInput.personalData.birthYear &&
      this.calculationInput.personalData.birthMonth
    )
    {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      let age = currentYear - this.calculationInput.personalData.birthYear;

      if (
        currentMonth <
        this.months.indexOf(this.calculationInput.personalData.birthMonth)
      )
      {
        age--;
      }
      return age > 55;
    }
    return false;
  }

  // public getAnnualizedPensionableEarnings() {
  //   const rrspContribution = this.assumptionsFG.controls.rrspContribution.value;
  //   const annualizedPensionableEarnings =
  //     this.calculationInput.statementData?.annualizedPensionableEarnings;

  //   if (
  //     rrspContribution !== null &&
  //     rrspContribution !== undefined &&
  //     annualizedPensionableEarnings !== null &&
  //     annualizedPensionableEarnings !== undefined
  //   ) {
  //     return (rrspContribution / 100) * annualizedPensionableEarnings;
  //   } else {
  //     return null;
  //   }
  // }

  // public getRrspContribution() {
  //   return this.assumptionsFG.value?.rrspContribution !== undefined &&
  //     this.assumptionsFG.value?.rrspContribution !== null
  //     ? (this.assumptionsFG.value.rrspContribution as number)
  //     : 0;
  // }

  public getMinValue(num1: any, num2: any)
  {
    return Math.min(num1, num2);
  }

  // public getAnnualizedPensionableBiWeeklyEarnings() {
  //   const annualizedPensionableEarnings =
  //     this.getAnnualizedPensionableEarnings();

  //   if (
  //     annualizedPensionableEarnings !== null &&
  //     annualizedPensionableEarnings !== undefined
  //   ) {
  //     return annualizedPensionableEarnings / 26;
  //   } else {
  //     return null;
  //   }
  // }

  ngOnDestroy(): void
  {
    if (this.calcInput$) this.calcInput$.unsubscribe();
  }

  public getEarningsYear()
  {
    return this.calculationInput.statementData?.joinYear <= 2022 ? 2022 : 2023;
  }
}
