import { Component } from '@angular/core';

@Component({
  selector: 'app-rrsp-contribution-info',
  templateUrl: './rrsp-contribution-info.component.html',
  styleUrls: ['./rrsp-contribution-info.component.scss']
})
export class RrspContributionInfoComponent {

}
