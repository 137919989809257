import { Component } from '@angular/core';

@Component({
  selector: 'app-planned-retirement-info',
  templateUrl: './planned-retirement-info.component.html',
  styleUrls: ['./planned-retirement-info.component.scss']
})
export class PlannedRetirementInfoComponent {

}
