import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoticeComponent {
  @Input() header: string = '';
}
