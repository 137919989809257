import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser } from '@angular/common';
import
{
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { ComparisonResult } from 'src/app/models/ComparisonResult';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { RiskTolerance } from './../../models/ComparisonInput';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() calculationInput!: CalculationInput;
  @Input() comparisonResults!: ComparisonResult[];
  public isRotated: boolean = false;
  private root!: am5.Root;
  private theme!: am5.Theme;
  private isMobile!: boolean;

  private screenWidth!: number;
  private screenWidth$!: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private screenSizeService: ScreenSizeService,
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit(): void
  {
    this.root = am5.Root.new('chartdiv');
    this.theme = am5.Theme.new(this.root);

    this.theme.rule('Grid', ['base']).setAll({
      strokeOpacity: 0.1,
    });
    this.root.setThemes([am5themes_Animated.new(this.root), this.theme]);

    this.screenWidth$ = this.screenSizeService.screenWidth$.subscribe(
      (width) =>
      {
        this.screenWidth = width;
        if (this.isMobile && this.screenWidth > 900)
        {
          this.buildVerticalGrid();
          this.isMobile = false;
        } else if (!this.isMobile && this.screenWidth < 900)
        {
          this.buildHorizontalGrid();
          this.isMobile = true;
        }
      }
    );

    if (window.innerWidth < 900) this.buildHorizontalGrid();
    else this.buildVerticalGrid();
  }

  ngOnDestroy(): void
  {
    if (this.screenWidth$) this.screenWidth$.unsubscribe();
  }

  browserOnly(f: () => void)
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.zone.runOutsideAngular(() =>
      {
        f();
      });
    }
  }

  ngOnChanges()
  {
    if (this.root)
    {
      if (window.innerWidth < 900) this.buildHorizontalGrid();
      else this.buildVerticalGrid();
    }
  }

  private buildHorizontalGrid(): void
  {
    this.root.container.children.clear();

    const data = this.comparisonResults.filter(
      (result) =>
        result.riskTolerance! ===
        RiskTolerance[this.calculationInput.assumptions.riskTolerance!]
          .toLocaleLowerCase()
          .replace('very', 'very ')
    );

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        layout: this.root.verticalLayout,
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 0,
    });
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'fractionalAge',
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    yAxis.children.unshift(
      am5.Label.new(this.root, {
        text: 'Retirement age',
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
      })
    );

    yAxis.data.setAll(data);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        extraMax: 0.1,
        numberFormat: "$#,###|$(#,###s)|$'-'",
        renderer: am5xy.AxisRendererX.new(this.root, {
          strokeOpacity: 0.1,
          centerY: am5.p50,
          minGridDistance: 25,
        }),
      })
    );

    xAxis.children.push(
      am5.Label.new(this.root, {
        text: 'Estimated annual pension income',
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        clickTarget: 'none',
        layout: am5.GridLayout.new(this.root, {
          maxColumns: 3,
          fixedWidthGrid: true,
        }),
      })
    );

    legend.labels.template.setAll({
      fontSize: 13,
      textAlign: 'left',
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0,
      breakWords: true,
      maxWidth: 200,
      oversizedBehavior: 'wrap',
    });

    legend.markers.template.setAll({
      width: 15,
      height: 15,
    });

    let xRenderer = xAxis.get('renderer');
    xRenderer.labels.template.setAll({
      rotation: -70,
    });

    const makeSeries = (
      name: any,
      fieldName: any,
      color: string,
      isStacked: boolean,
      tooltipTextColor?: string
    ) =>
    {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: name,
          stacked: isStacked,
          xAxis: xAxis,
          yAxis: yAxis,
          baseAxis: yAxis,
          valueXField: fieldName,
          categoryYField: 'fractionalAge',
          fill: am5.color(color),
        })
      );

      const textColorStyle = tooltipTextColor
        ? `color: ${ tooltipTextColor };`
        : '';

      series.columns.template.setAll({
        tooltipHTML: `<span style="${ textColorStyle }">${ name }: <b>\${valueY.formatNumber("#,###")}</b><hr/> Age: <b>{categoryY}</b></span>`,
        tooltipY: am5.percent(180),
        height: am5.p100,
        strokeOpacity: 0,
      });
      series.data.setAll(data);
      series.appear();
      legend.data.push(series);
    };

    this.makeAllSeries(makeSeries);
    chart.appear(1000, 100);
  }



  private buildVerticalGrid(): void
  {
    this.root.container.children.clear();

    const data = this.comparisonResults.filter(
      (result) =>
        result.riskTolerance! ===
        RiskTolerance[this.calculationInput.assumptions.riskTolerance!]
          .toLocaleLowerCase()
          .replace('very', 'very ')
    );

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        layout: this.root.verticalLayout,
      })
    );

    let yRenderer = { minGridDistance: 25, rotation: -70 };
    let xRenderer = {
      strokeOpacity: 0.1,
      centerY: am5.p50,
      minGridDistance: 25,
    };

    let xAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'fractionalAge',
        renderer: am5xy.AxisRendererX.new(this.root, xRenderer),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xAxis.children.push(
      am5.Label.new(this.root, {
        text: 'Retirement age',
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    xAxis.data.setAll(data);

    let yAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        extraMax: 0.1,
        numberFormat: "$#,###|$(#,###s)|$'-'",
        renderer: am5xy.AxisRendererY.new(this.root, yRenderer),
      })
    );

    yAxis.children.unshift(
      am5.Label.new(this.root, {
        text: 'Estimated annual pension income',
        rotation: -90,
        centerX: am5.p50,
        y: am5.p50,
      })
    );

    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        clickTarget: 'none',
        layout: am5.GridLayout.new(this.root, {
          maxColumns: 3,
          fixedWidthGrid: true,
        }),
      })
    );

    legend.labels.template.setAll({
      fontSize: 13,
      textAlign: 'left',
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0,
      breakWords: true,
      maxWidth: 200,
      oversizedBehavior: 'wrap',
    });

    legend.markers.template.setAll({
      width: 0,
      height: 0,
    });

    const makeSeries = (
      name: any,
      fieldName: any,
      color: string,
      isStacked: boolean,
      tooltipTextColor?: string
    ) =>
    {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: name,
          stacked: isStacked,
          xAxis: xAxis,
          yAxis: yAxis,
          baseAxis: xAxis,
          valueYField: fieldName,
          categoryXField: 'fractionalAge',
          fill: am5.color(color),
        })
      );

      const textColorStyle = tooltipTextColor
        ? `color: ${ tooltipTextColor };`
        : '';

      series.columns.template.setAll({
        //tooltipHTML: `<span style="${ textColorStyle }">${ name }: <b>\${valueY.formatNumber("#,###")}</b><hr/> Age: <b>{categoryX}</b></span>`,
        //tooltipY: am5.percent(180),
        height: am5.p100,
        strokeOpacity: 0,
      });
      series.data.setAll(data);
      series.appear();
      legend.data.push(series);
    };


    this.makeAllSeries(makeSeries);

    chart.appear(1000, 100);
  }

  private makeAllSeries(makeSeries: (name: any, fieldName: any, color: string, isStacked: boolean, tooltipTextColor?: string) => void)
  {
    makeSeries(
      //'Annual income from your Current DB pension plan (100% funded by AMA)',
      '',
      'dbAnnualPension',
      '#00539B',
      false,
      'white'
    );
    // makeSeries(
    //   'Annual income from your New Group RRSP (funded by AMA)',
    //   'dcAnnualPensionFromEr',
    //   '#FDB733',
    //   false
    // );
    // makeSeries(
    //   'Annual income from your New Group RRSP (funded by you)',
    //   'dcAnnualPensionFromEe',
    //   '#7AC143',
    //   true
    // );
  }

  getChartContainer(): HTMLElement
  {
    return this.elementRef.nativeElement.querySelector('#chartdiv');
  }
}
