import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent{

  openPrivacyPolicyPage(): void {
    const privacyPolicyUrl =
      'https://amaonline.sharepoint.com/sites/backyard/Essentials/policies/corporate-policies/Related%20Resources/Employee%20Privacy%20Policy.pdf';

    // Open the link in a new tab
    window.open(privacyPolicyUrl, '_blank');
  }
  
}
