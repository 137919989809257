<b>Assumptions</b>
<ul>
  <li>You will remain employed at AMA until you retire.</li>
  <li>
    DB pension limits under the Income Tax Act will increase at 2.50% per year.
  </li>
  <li>
    There will be no future upgrades to the current DB pension plan. To the extent future
    upgrades are provided, the DB Pension values in this model will be understated.
  </li>
  <li>
    This tool does not consider any additional voluntary contributions to other retirement
    savings or pension plans, and only reflects the potential earnings resulting from your AMA DB
    pension plan.
  </li>
</ul>