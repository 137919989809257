<app-expansion-panel [(panelOpenState)]="appService.nextStepsExpanded" [enabled]="appService.nextStepsEnabled">
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_nextsteps.svg" />
    <h1>Where to go from here...</h1>
  </div>
  <div>
    <!-- <span class="blue-header">Need more information?</span> -->
    <p style="margin-top: 10px !important;">In the footer below, you’ll find contact information for our
      retirement savings team, in case you have any questions
      about your pension. You can return to this tool as often as you like to run scenarios and review information. We
      also
      recommend discussing your unique financial situation with a qualified financial advisor.
    </p>
    <!-- <p style="margin-bottom: 20px !important;">
      If you haven’t already done so, we highly recommend that you discuss your options and review the results of this
      tool with your family, and get the advice of a financial advisor or other qualified advisor before making a final
      choice.
    </p>
    <span class="blue-header" style="padding-bottom: 10px">Ready to make a choice?</span>
    <p style="margin-top: 10px !important;">
      Congratulations! Log onto our <a href="https://pdsprodv7.corp.ads/application/application.aspx"
        target="_blank"><u>Employee Data Portal
          (EDP)</u></a> to register your
      choice. If you are on a leave of absence, you will receive a DocuSign email from Human Resources that will contain
      a form for you to complete your decision and sign electronically.
    </p> -->
  </div>
</app-expansion-panel>