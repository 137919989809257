import { Component, Input, OnChanges } from '@angular/core';
import { CalculationInput } from 'src/app/models/CalculationInput';
import { RiskTolerance } from 'src/app/models/ComparisonInput';
import { ComparisonResult } from 'src/app/models/ComparisonResult';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-income-table',
  templateUrl: './income-table.component.html',
  styleUrls: ['./income-table.component.scss']
})
export class IncomeTableComponent implements OnChanges{

  @Input() calculationInput!: CalculationInput;
  @Input() comparisonResults!: ComparisonResult[];
  private RiskTolerance = RiskTolerance;

  filteredResults: ComparisonResult[] = [];
  risk!: string;

  ngOnChanges(): void {

    if (this.calculationInput.assumptions.riskTolerance && this.comparisonResults) {
      this.risk = this.RiskTolerance[this.calculationInput.assumptions.riskTolerance].replace('Very', 'Very ');
      if (this.risk) {
        this.filteredResults = this.comparisonResults.filter(result => result.riskTolerance === this.risk.toLocaleLowerCase());
      } else {
        this.filteredResults = this.comparisonResults;
      }
    }
  }

}
