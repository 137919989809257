import { Component } from '@angular/core';

@Component({
  selector: 'app-spouse-info',
  templateUrl: './spouse-info.component.html',
  styleUrls: ['./spouse-info.component.scss']
})
export class SpouseInfoComponent {

}
