<app-expansion-panel [(panelOpenState)]="appService.comparisonExpanded" [enabled]="appService.comparisonEnabled">
  <div class="panel_header" header>
    <img src="../../../images/AMA_icon_forecast.svg" />
    <h1>Your results</h1>
  </div>
  <div *ngIf="calculationInput && comparisonResults">
    <div>
      <!-- <p>
        See your potential income in the current pension plan compared to the
        new Group RRSP plan.
      </p>
      <app-notice header="Remember!">
        <p>
          There are lots of things to consider as you compare the two plans. For
          example, if you stay in the DB plan, you can still contribute to AMA's
          RRSP on a voluntary basis. View this
          <a href="../../../images/Quick Summary - for posting.pdf" target="_blank">
            <u>quick comparison</u></a>
          for more details.
        </p>
      </app-notice> -->
    </div>
    <p>In each view, you get a slightly different way of looking at things.</p>
    <p>
      <b>View 1: </b>
      Looking for something simple? This view shows your estimated annual pension income
      at your chosen retirement date.
    </p>
    <p>
      <b>View 2:</b>
      Not sure when you’ll retire? This view shows your estimated annual pension income
      for all potential retirement ages so you can see which plan is most
      valuable at each age.
    </p>
    <app-hint>
      You can use the <strong><i>Change Assumptions</i></strong> button at the
      bottom of this section to modify your assumptions; your results will
      update automatically. Remember, the tool does not save any data so you might want to use the
      <strong><i>Print</i></strong> button at the bottom to print your
      scenario.</app-hint>
    <app-view-header>
      View 1: Your estimated annual pension income at your chosen retirement age
    </app-view-header>
    <br />

    <!--  -->
    <div class="retirement-grid page-break-after">
      <div class="grid-box">
        <p>
          If you retire at age
          {{ calculationInput.assumptions.retirementAge }} years:
        </p>
      </div>
      <div class="grid-box">
        <p>DB Pension Plan</p>
      </div>

      <div class="grid-box">
        <p>Estimated annual pension income:</p>
      </div>
      <div class="grid-box">
        <label class=""><strong>${{ retireAgeFirstMonth.dbAnnualPension | number }}</strong><img
            (click)="openCurrentPensionPlanDialog()" src="../../../images/info icon.svg" alt="" />
        </label>
        <!-- <p class="align-bottom" style="color: black;">100% funded by AMA</p>
        <p style="color: black;">
          This amount is fairly certain if you remain at AMA until you retire, but assumes no future plan upgrades and
          therefore may be understated.
        </p> -->
      </div>
    </div>
    <!--  -->
    <div class="page-break-after">
      <div class="grids">
        <app-view-header>
          View 2: Your estimated annual pension income for all potential retirement ages
        </app-view-header>
        <section class="noprint">
          <!-- <p>
            Your chosen risk level:
            <b>{{ retireAgeFirstMonth.riskTolerance | titlecase }}</b>
          </p> -->
          <div>
            <p>Show as:</p>
            <mat-button-toggle-group name="displayToggle" [(ngModel)]="displayToggle" aria-label="Font Style">
              <mat-button-toggle value="chart">Chart</mat-button-toggle>
              <mat-button-toggle value="table">Table</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </section>

        <app-stacked-bar-chart *ngIf="displayToggle === 'chart'" [comparisonResults]="comparisonResults"
          [calculationInput]="calculationInput">
        </app-stacked-bar-chart>
        <app-income-table *ngIf="displayToggle === 'table'" [comparisonResults]="comparisonResults"
          [calculationInput]="calculationInput"></app-income-table>
      </div>
    </div>
    <div class="page-break-before">
      <app-income-table class="print" [comparisonResults]="comparisonResults"
        [calculationInput]="calculationInput"></app-income-table>
    </div>
    <app-hint>
      You should review the assumptions used by the model, which have a significant impact on results. For example, the
      'Estimated annual pension income' may be understated because it assumes no future plan upgrades (which are not
      promised, but have been provided
      from time-to-time historically).
      Even a small deviation from these assumptions could have a significant impact on your estimated pension income.
    </app-hint>
    <app-notice header="Before you move on…">
      <p>
        We strongly recommend that you run and print/save multiple scenarios using this tool and review the results with
        a
        financial advisor or other qualified advisor.
      </p>
    </app-notice>
  </div>

  <div class="footer">
    <button mat-flat-button color="primary" [class.spinner]="isFormattingPrint" [disabled]="isFormattingPrint"
      (click)="print()">Print</button>

    <button mat-button color="primary" class="change-assumptions__btn" (click)="openResultsDialog()">
      Change Assumptions

    </button>
    <button mat-flat-button (click)="next()" color="primary" style="float: right">
      Next Steps
    </button>
  </div>
</app-expansion-panel>