import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-statement-dialogs',
  templateUrl: './statement-dialogs.component.html',
  styleUrls: ['./statement-dialogs.component.scss']
})
export class StatementDialogsComponent {
  @Input() content!: string;  
  @Input() imagePath!: string;  


  sanitizedContent!: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<StatementDialogsComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { content: string, imagePath: string }
  ) {
    this.content = data.content;
    this.imagePath = "../images/" + data.imagePath;

    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
